<template>
  <header
    class="flex items-center justify-center w-full h-50vh border-b-8 our-products-bg border-primary"
  >
    <div class="font-medium tracking-wide text-center text-white md:w-1/2">
      <h1 class="m-3 text-4xl font-bold uppercase md:text-6xl mt-32">
        {{ $t('products.h1') }}
      </h1>
      <p class="text-2xl font-medium">{{ $t('products.description') }}</p>
    </div>
  </header>
  <div v-if="products" class="container px-5 mx-auto my-16 md:px-8">
    <Swiper
      :slides-per-view="1"
      :spaceBetween="30"
      :pagination="{
        clickable: true,
      }"
      :modules="modules"
      :breakpoints="{
        '640': {
          slidesPerView: 2,
          spaceBetween: 20,
        },
        '768': {
          slidesPerView: 3,
          spaceBetween: 40,
        },
        '1024': {
          slidesPerView: 3,
          spaceBetween: 50,
        },
      }"
      style="h-full"
    >
      <SwiperSlide
        v-for="product in products"
        :key="product.product_id"
        class="relative h-48 overflow-hidden shadow-lg cursor-pointer rounded-3xl hover:shadow-xl md:h-56"
        @click="$router.push(`/product/${product.product_id}`)"
      >
        <img
          loading="lazy"
          alt=""
          :src="URL + product.image"
          class="object-cover w-full h-48 md:h-56"
        />
        <div class="absolute inset-0 flex items-end p-5 overloay">
          <h4 class="mx-auto text-2xl text-center text-white">
            {{ $t('lang') == 'ar' ? product.name_ar : product.name_en }}
          </h4>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
  <div class="flex justify-center w-full mb-16 mt-11">
    <button
      @click="$router.push('/AllProducts')"
      class="px-8 py-3 text-xl transform border-4 rounded-lg hover:bg-primary hover:text-white hover:scale-105 border-primary text-dark"
    >
      {{ $t('products.seeAllProducts') }}
    </button>
  </div>

  <section
    class="flex flex-wrap items-center justify-between my-8 md:flex-nowrap md:justify-center"
  >
    <div class="w-full p-5 md:w-1/2">
      <h3 class="text-3xl text-primary">{{ $t('products.quality') }}</h3>
      <p>
        {{ $t('products.quality_details') }}
      </p>
    </div>
    <img src="../assets/image.png" class="w-full h-96 md:w-1/2" />
  </section>

  <section
    class="flex flex-wrap-reverse items-center justify-between my-8 md:flex-nowrap md:justify-center"
  >
    <img src="../assets/image.png" class="w-full h-96 md:w-1/2" />
    <div class="w-full p-5 md:w-1/2">
      <h3 class="text-3xl text-primary">{{ $t('products.experience') }}</h3>
      <p>
        {{ $t('products.experience_details') }}
      </p>
    </div>
  </section>
  <span class="block text-4xl text-red-600"> </span>
</template>
<script>
import { Navigation, A11y } from 'swiper'

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue/swiper-vue'
import { useStore } from 'vuex'
// import { useI18n } from 'vue-i18n'
import { computed } from '@vue/reactivity'
export default {
  name: 'Products',
  components: {
    Swiper,
    SwiperSlide,
  },
  setup() {
    const store = useStore()
    const products = computed(() => store.getters.products)
    const slidesPerView = computed(() => (window.innerWidth < 768 ? 1 : 3))
    // const { t, locale } = useI18n({ useScope: 'global' })

    return {
      // URL: process.env.VUE_APP_URL,
      URL: 'https://api.nowarat.ly/',
      products,
      modules: [Navigation, A11y],
      slidesPerView,
      // locale,
      // t,
    }
  },
}
</script>
<style scoped>
.our-products-bg {
  background-image: url(../assets/donation-box-with-goods-quarantine-help-concept-neighbors.jpg);
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.overloay {
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.4);
}
</style>
